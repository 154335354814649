/*======================================
 8. Home-style-5 
 =======================================*/

 /* 8.1 wpo-about-area-s4  */

 .wpo-about-area-s5{
	 padding-bottom: 20px;
	.wpo-about-img{
		padding: 20px;
		border: 1px solid #4B4B4B;
		border-radius: 360px 360px 0 0;

		img{
			border-radius: 360px 360px 0 0;
		}
	}
 }