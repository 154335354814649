/*==========================================
9. wpo-about-section
===========================================*/

// 9.1 wpo-fun-fact-section-s2

.wpo-fun-fact-section-s2{
    text-align: center;
    background: url(../../images/funfact.jpg) no-repeat center center;
    position: relative;
    background-size: cover;
    z-index: 1;
    background-attachment: fixed;
    padding: 100px 0;


    &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $dark-gray;
        content: "";
        z-index: -1;
        opacity: .4;
    }

    .grid{
        h3{
            color: $white;
        }

        h3 + p {
            color: $white;
        }
    }
}
